import React from 'react'

import './style.scss'

const Tags = ({ data, limit = 100 }) => {
  return (
    <div className="d-flex flex-wrap">
      {data.slice(0, limit).map(tag => (
        <span key={tag} className="c-tag mb-1 mr-1">
          {tag}
        </span>
      ))}
    </div>
  )
}

export default Tags
