import React from 'react'

import './style.scss'

import { Link } from 'gatsby'
import Img from 'gatsby-image'
import Tags from '../Tags/index'

const CardTeaser = ({ data }) => {
  const {
    frontmatter: { intro_title, title, tags, cover_image },
    fields: { slug },
  } = data
  return (
    <Link to={slug}>
      <article className="c-card-teaser position-relative bg-white">
        <div className="c-card-teaser__image">
          <Img fluid={cover_image.childImageSharp.fluid} />
        </div>
        <div className="c-card-teaser__content position-absolute p-2 p-md-3 d-flex flex-column">
          <h6 className="text-white">{intro_title}</h6>
          <h2 className="text-white c-card-teaser__title">{title}</h2>
          <span className="c-card-teaser__more">Read more</span>
          {tags && (
            <footer className="mt-auto d-none d-md-block">
              <Tags limit={4} data={tags} />
            </footer>
          )}
        </div>
      </article>
    </Link>
  )
}

export default CardTeaser
