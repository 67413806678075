import React from 'react'
import CardTeaser from '../components/CardTeaser/index'
import CTABlock from '../components/CTABlock/index'
import HeroSingle from '../components/HeroSingle/index'
import Layout from '../components/Layout/index'
import { graphql } from 'gatsby'
import MassiveTitle from '../components/MassiveTitle'
import CTABanner from '../components/CTABanner'

class Index extends React.Component {
  render() {
    const { data, location } = this.props

    return (
      <Layout location={location}>
        <div className="bg-light pb-md-5">
          <section className="position-relative">
            <HeroSingle
              introTitle={'Learning'}
              title={
                'Learn how to turn your data into a proven system to engineer success'
              }
            />
            <CTABlock compact />
          </section>
          <div className="u-pull-up">
            <MassiveTitle modifiers={['z0']}>Learn</MassiveTitle>
          </div>
          <div className="container">
            <div className="row">
              {data.learnItems.edges.map(learn => (
                <div className="col-sm-6 col-lg-4 mb-3" key={learn.node.id}>
                  <CardTeaser data={learn.node} />
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="bg-light c-footer">
          <CTABanner />
        </div>
      </Layout>
    )
  }
}

export default Index

export const LearnOverviewQuery = graphql`
  query LearnOverviewQuery {
    learnItems: allMdx(
      filter: { frontmatter: { type: { eq: "learn" } } }
      sort: { order: ASC, fields: frontmatter___priority }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            tags
            intro_title
            cover_image {
              childImageSharp {
                fluid(maxWidth: 500, maxHeight: 500) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`
